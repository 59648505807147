import React, { Component } from 'react';
import { Flex, Box, Text, Card, Subhead } from 'rebass';
import Container from './Container';
import BodyText from './BodyText';

class AboutBody extends Component {
  render() {
    const { body, title, bodyTitle } = this.props.content;

    return (
      <Container>
        <Flex flexDirection="column">
          <Box mx="auto" my={5} px="10%" pt={5}>
            <BodyText
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
          </Box>
        </Flex>
      </Container>
    );
  }
}

export default AboutBody;
