import React, { Component } from 'react';
import AboutBanner from '../components/AboutBanner';
import AboutBody from '../components/AboutBody';
import Page from '../components/Page';
import { graphql } from 'gatsby';

class AboutPage extends Component {
  render() {
    return (
      <div>
        <Page>
          <AboutBanner data={this.props.data.contentfulPages} />
          <AboutBody content={this.props.data.contentfulPages} />
        </Page>
      </div>
    );
  }
}

export default AboutPage;

export const query = graphql`
  query PagesQuery {
    contentfulPages(title: { eq: "About" }) {
      id
      header
      bodyTitle
      body {
        body
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`;
